/* Medium Devices, Desktops */
@media only screen and (max-width:1024px){

    /*Typography*/
    h2{font-size:2.5em;}
    h3{font-size:1.5em;}

    /*forms*/
    .parsley-errors-list{margin:0 0 5px;}

    /*overlay*/
	.overlay{width:90%;}
    	.overlay header, .overlay .cont{padding:15px;}

	/*content*/
    .info{margin-left:0;}

    /*form*/
	.frm .radiosCont label{width:92%;}
    .parsley-errors-list li{font-size:11px;}
    .unitType{font-size:11px;}
    .frm fieldset .groupRight .uiIcon{width:42px; padding:0;}
    select{border-radius:0;}
    #global .frm select{height:40px !important; background:#ffffff !important; padding-top:10px !important; padding-bottom:10px !important;}
	.customInputCont li + li{margin:0 0 0 24px;}
    .frm input[type="checkbox"]{margin:4px 5px 0 0;}
	.frm small{margin:0;}

    /* Phase */
    .phase header{padding:15px;}
    	.phase header h3{font-size:16px;}
    	.phase .cont{padding:24px 15px;}
    		.phase .icons{padding-bottom:0;}
			.phase .cont figure{margin:13px 0 0; text-align:center;}
				.phase .cont figure img{display:inline-block;}
    		.frm .icons figure{width:15%;}
			.icons li{margin:0 0 20px;}
				.phase header .info{float:left; margin:3px 0 0; clear:left;}

    /* aside */
	.page404 aside{margin:20px 0 0;}
	aside .cont{min-height:140px;}

    /* footer */
    footer .container > ul{float:left; width:100%; margin:0 0 18px; padding:0;}
		footer .container ul li{float:none; display:inline-block; padding:0; margin:0 0 18px;}
			footer li:first-child{border-left:1px solid #e4e4e4;}
			footer li:last-child{border-right:1px solid #e4e4e4;}
				footer li a{padding:12px;}
			footer .container .socialLinks, footer .container .copyrightCont{float:right; width:auto; margin:0;}
				footer .container .copyrightCont{float:left;}
					footer .copyrightCont li, footer .container .socialLinks li{margin:0; border:none;}

	/* estimate */
	#estimateBreakdown .btnCont input, #estimateBreakdown .btnCont{margin:0 0 12px;}
	#estimateBreakdown header{padding:15px;}
		#estimateBreakdown h3{margin:0;}
		.stepsAhead, .extraInfo{padding:0 33px 0 15px;}
	.estimateList{width:100%; float:left;}
			.estimatePrice{float:left; width:100%; margin:12px 0 0;}
		.estimateList h4, .estimatePrice{text-align:left;}
	.estimateList .listCont span{width:90%;}
	.estimateList .listCont i{width:4%;}
	.estimateList dl{border-bottom:1px dotted #eee; margin:15px 0 0; padding:0 15px 15px;}
		.estimateList .row-2{border:none;}
		.estimateList dt, .estimateList dd{padding:0; margin-top:12px !important;}
		.estimateList .totalPriceRange{padding:15px;}
	#estimatePrice{margin:0 0 15px;}
	#estimateBreakdown .cont{padding:22px 0 15px;}
	.ribbon{max-width:100%; position:absolute; z-index:12; right:0; width:44%; margin:-15px 0 0; padding:18px; font-size:12px;}
	#frmEstimate .btnCont .preloader{right:10px; color:#fff;}
	.triangle{border-width:28px 153px 0;}
}

/* Small Devices, Tablets */
@media only screen and (max-width:768px){
	.triangle{border-width:28px 158px 0;}
}

/* Medium Devices, Phones */
@media only screen and (max-width:680px){

	/* aside */
	aside{float:left; margin:0;}

	/* phase */
	.phase .cont{padding:20px 15px 15px;}
		.frm textarea{width:92%;}
	#signatureParent{margin:0 0 15px;}
	.careplanKeyLink{display:none !important;}

	/* estimate */
	.triangle{border-width:17px 73px 0; bottom:-17px;}
	.estimateList .col-md-4{width:100%;}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width:480px) {

	/* overlay */
	#overlay h3 {
		width: 80%;
		font-size: 1.2em;
	}

	/* content */
	.listCont span, .listCont div {
		width: 92%;
	}

	.ribbon {
		display: none;
	}

	/* table */
	.responsiveTable table, .responsiveTable thead, .responsiveTable tbody, .responsiveTable th, .responsiveTable td, .responsiveTable tr {
		display: block;
		font-size: 11px;
	}

	.responsiveTable thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.responsiveTable tr {
		border: 1px solid #ccc;
	}

	#global .responsiveTable td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding: 12px 8px 18px;
		white-space: normal;
		text-align: right;
		width: auto;
	}

	#global .responsiveTable td.selectCont {
		padding: 25px 8px;
	}

	#global .responsiveTable td:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 45%;
		padding: 12px 8px 18px;
		text-align: left;
		color: #6e6e6e;
		font-weight: bold;
	}

	#global .responsiveTable td:before {
		content: attr(data-title);
	}

	.frm .actions {
		float: none;
		width: auto;
		text-align: right;
	}

	#global .careplanTable td, #global .careplanTable td:before {
		height: 30px;
	}
}
/* Custom, iPhone Retina */
@media only screen and (max-width:320px){

	/* estimate */
	#estimateBreakdown .btnCont input{margin:0 0 12px;}

	/* footer */
	footer .container .copyrightCont{margin:5px 0 0; padding-right:0;}
}