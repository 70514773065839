/* Reset */
html, body{height:100%;}
body, ul, li, ol, dl, dt, dd, p, form, div, h1, h2, h3, h4, h5, h6, label, a, fieldset, img, span, input, button{margin:0; padding:0; box-sizing:border-box;}
img, fieldset{border:0;}
ul{list-style:none;}
a{text-decoration:none; outline:none; color:#768187; transition:color .2s ease-out;}
	a:hover{text-decoration:none; outline:none; color:#34A9DA;}
	a:focus{outline:none; text-decoration:none;}
	a, button, input{-webkit-tap-highlight-color:rgba(0,0,0,0);}
h1, h2, h3, h4, h5, h6, p{font-weight:normal; float:left; width:100%;}
p{float:left; width:100%; margin:0 0 10px; text-align:left;}
	p a, p .fa{color:#1B98DF;}
small{font-size:85%;}
iframe{border:none; width:100%;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section{display:block; box-sizing:border-box;}
textarea, input[type="text"], input[type="button"], input[type="submit"]{-webkit-appearance:none; border-radius:0;}
	::-webkit-input-placeholder{text-transform:none;}
	::-moz-placeholder{text-transform:none;}
	:-ms-input-placeholder{text-transform:none;}
	input:focus::-webkit-input-placeholder{color:transparent;}
	input:focus::-moz-placeholder{color:transparent;}
	input:focus:-ms-input-placeholder{color:transparent;}
	button:focus, button:active{outline:0;}
	button::-moz-focus-inner,
	input[type="reset"]::-moz-focus-inner,
	input[type="button"]::-moz-focus-inner,
	input[type="submit"]::-moz-focus-inner,
	input[type="file"] > input[type="button"]::-moz-focus-inner{border:1px dotted transparent;}
input[type='number']{-moz-appearance:textfield;}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{-webkit-appearance:none;}
table{width:100%; border-collapse:collapse;}
	table th, table td{vertical-align:middle; text-align:center;}