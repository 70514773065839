/* Typography */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype"), url("../fonts/OpenSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;}
html{font-size:10px; -webkit-font-smoothing:antialiased;}
body{font:1.3em Arial, 'Helvetica Neue', Helvetica, sans-serif; color:#6E6E6E;}
	h2{font-size:3.5em; color:#129adb; margin:0 0 10px; letter-spacing:-1px;}
	h3{font-size:1.7em; color: #6E6E6E; margin:0 0 20px;}
	h4, #overlay .spacingCont dt{font-size:1.1em; color:#545B60; font-weight:bold; margin:0 0 12px;}
	p{margin:0 0 10px; text-align:left; line-height:20px;}
		p a, p .fa{color:#1B98DF;}
	small{float:left; width:100%; font-size:85%;}
		small a{text-decoration:underline; color:#6e6e6e; transition:all .2s ease-out;}
		small a:hover{text-decoration:underline;}
    a, small a:hover{color:#34A9DA;}

/* Core Structure */
body{background:#fff; overflow-y:scroll;}
	body.stop-scrolling{overflow-y:scroll;}
	#global{position:relative;}
		.container{transition: all 0.25s;}
		#content{min-height:550px;}
			/*.formContents{display:none;}*/
				.no-cookies .formContents, html.errorAjax .formContents, .alertCookies, .cookies .alertCont, .alertAjax{display:none;}
				.no-cookies .alertCookies, .no-cookies .alertCont, .errorAjax .alertCont, .errorAjax .alertAjax{display:block;}
			.externalLink{float:left; width:100%; color:#207CBA; clear:both;}
				.externalLink + .externalLink{margin-top:8px;}
				.externalLink i{float:left; width:12%; margin:2px 0 0;}
				.externalLink span{display:inline-block; margin:1px 0 0 4px; width:auto;}
					.externalLink:hover span{text-decoration:underline;}
			.uiIcon .svg{fill:#555;}
		.normalList{list-style-type:disc; list-style-position:inside; margin:0; float:left;}
			.normalList li{float:left; width:100%; margin:0 0 8px;}
		.firstArticle{border-bottom:1px dotted #eee; margin-bottom:20px;}
		.hideElem{display:none;}
	
/* Header */
#mastHead, footer{padding:10px 0 6px;}
	#mastHead{margin:0 0 25px; box-shadow:0 0 20px 20px rgba(239, 238, 238, 0.86); border-bottom:1px solid #EEEDED;}
	footer{box-shadow:-1px 9px 16px 1px rgba(239, 238, 238, 0.85) inset; border-top:1px solid #EEEDED;}
	.contHeader{margin:0 0 12px;}
	#mastHead h1{width:auto;}
		.svg h1 img{width:172px; height:99px;}
	.headTitle{float:left; width:100%;}
	.contHeader h3{margin:0 0 12px;}

/* Content */
.step, .phase, .highlightText{float:left; width:100%;}
			
/* List*/
.listCont{float:left; width:100%; margin:0 0 20px;}
	.container .listCont li{float:left; width:100%; margin:12px 0 0;}
	.listCont li:first-child{margin:0;}
		.listCont i{float:left; width:2%; margin:2px 5px 0 0; color:#7DA711;}
		.listCont span, .listCont div{width:97%; float:right;}
		.includedPackCont .listCont span{width:90%;}
		.installationInfo .listCont span, .financeInfo .listCont span{width:94%;}
	.listCont a i, .listCont a{color:#0270b9;}
		.listCont a{font-weight:bold;}
		.listCont a i{float:none; display:inline-block; width:auto; margin:0;}
	.listCont p{margin:0 0 6px;}
	
/* Aside */
aside{display:none;}
aside .cont{float:left; width:100%; padding:15px; margin:0 0 15px; background:#fafafa; box-shadow:0 0 10px 10px #f6f6f6 inset; border-radius:4px;}
	aside .cont p a{font-weight:bold; color:#0270b9;}
		aside .btn{font-size:16px;}
			aside .btn:hover{color:#fff;}
.quoteSummaryPanel{display:none;}
	.quoteSummaryPanel ul, .quoteSummaryPanel li{float:left; width:100%;}
		.quoteSummaryPanel li{margin:10px 0 0;}
		.quoteSummaryPanel li:first-child{margin:0;}
.careplanKeyLink{display:none;}
.connectivity a{color:#000; cursor:none;}
.connectivity span strong .offline{color:red;}
			
/* Footer */
footer{margin-top:20px; padding:18px 0 0;}
	footer ul{padding-bottom:15px; margin:0 0 15px;}
		footer li{float:left; font-size:11px;  width:auto; padding:0 10px; border-left:1px solid #E4E4E4;}
			footer li:first-child{padding:0 10px 0 0; border:none;}
		footer .copyrightCont{padding-bottom:0;}
		footer a{color:#0270b9;}
	footer h3{font-size:11.5px; color:#646361; font-weight:bold; margin:0 0 4px;}
	footer .col-md-2 ul, footer .col-md-2 ul li{float:left; width:100%; margin:0 0 3px; border:none; padding:0;}
		footer .col-md-2 ul li:first-child{padding:0;}

/* Overlay */
.overlay{width:65%; max-width:760px; display:none; transform:translate3d(0, 0, 0); backface-visibility:hidden; perspective:1000;}
.overlay header, .overlayCont, .overlay .cont{float:left; width:100%; position:relative;}
	.overlay .b-close{position:absolute; top:0; right:0; z-index:10; padding:14px 18px;}
		.overlay .closeBtn .fa{color:#fff;}
	.overlay header{background:#1B98DF; color:#fff; font-size:14px; border-radius:6px 6px 0 0;}
		.overlay header, overlay .cont{float:left; width:100%; padding:15px 25px;}
		.overlay .cont{background:#fff; border-radius:0 0 6px 6px; padding:20px 25px;}
			.overlay h3{color:#fff; margin:0; width:95%;}
			overlay dl + dl{margin-top:20px; padding-top:20px; border-top:dotted 1px #ccc;}
			
/* Tabs */
.tabs {float:left; width:100%;}
	.tabs input{display:none;}
	.tabs section{display:none; padding:20px 0 0; border-top:1px solid #ddd; float:left; width:100%;}
	.tabs label{display:inline-block; margin:0 0 -1px; padding:15px 20px; font-weight:600; text-align:center; border:1px solid transparent;}
	.tabs label:hover{color:#888; cursor:pointer;}
	.tabs input:checked + label{color:#555; border:1px solid #ddd; border-top:2px solid orange; border-bottom:1px solid #fff;}
		#tab1:checked ~ #content1,
		#tab2:checked ~ #content2,
		#tab3:checked ~ #content3,
		#tab4:checked ~ #content4, #tab5:checked ~ #content5, #tab6:checked ~ #content6{display:block;}		

/* Form */
.frm{position:relative; float: left; width:100%;}
	.frm fieldset, .frm input[type=text], .frm input[type=email], .frm input[type=tel], .frm input[type=number] .frm input[type=password], .frm textarea, .frm select{float:left; margin:0 0 10px;}
		.frm fieldset{margin:0; border:none; float:left;}
		#global .frm input[type=radio], #global .frm input[type=checkbox]{float:left; margin-top:1px; margin-right:8px; width:10%;}
			.frm label{cursor:pointer; font-size:13px; float:left; margin:0 0 8px; color:#7f7e7e; font-weight: bold;}
			.frm small{float:left; width:100%; margin:12px 0 0;}
.frm input:not([type="submit"]), .frm input:not([type="checkbox"]), .frm input:not([type="radio"]), .frm textarea, .frm select{border:1px solid #ccc; font-size:13px; outline:none; transition:border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; width:100%; box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset; color:#555; padding:8px 12px; border-radius:3px;}
	.frm input:not([type="submit"]):active, .frm input:not([type="checkbox"]):active, .frm input:not([type="radio"]):active, .frm input:not([type="checkbox"]):focus, .frm input:not([type="radio"]):focus, textarea:active, .frm input:not([type="submit"]):focus,.frm textarea:focus{border-color:#1B98DF; box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6); outline:0 none;}
	.frm textarea{width:96%; resize:vertical; font:13px Arial, 'Helvetica Neue', Helvetica, sans-serif; color:#6E6E6E;}
		.frm input[type=checkbox]{width:auto !important; display:inline-block; margin:3px 12px 0 0;}
			.frm .checkbox label{width:auto; display:inline-block; font-size:13px; font-weight:bold;}
		#global .frm .radiosCont input{width:auto; float:left; margin:0 6px 0 0;}
		#global .frm .checkbox input, #global .frm .radiosCont input{border:none; padding:0;}
		#global .frm .radiosCont label{width:100%;}
	.btnCont{width:100%; float:left; margin:6px 0 20px;}
		.btnCont button, .btnCont input{margin:0 0 12px;}
			.btnCont button.pull-right{margin-left:12px;}
		.btnCont button:first-child{margin-left:0;}
			.btnCont button .fa{display:inline-block; margin:0 8px 0 0;}
		#frmInvalidPostCode .btnCont, #frmEstimate .btnCont{margin:20px 0;}
	.preloader{/* display:none; */display: inline-block;float: right;}
		#global .preloader, .loading .preloader{color:#95BE0E;}
			.loading .preloader{display:inline-block;}
			.loading p{margin:12px 0 0;}
		#global .phaseTable .preloader{margin:4px 0 0;}
	.frm input[type="submit"],.frm button, .btn{border:none; border-radius:6px; color:#fff; font-weight:bold; font-size:13px; outline:none; padding:12px 28px; transition:all 0.2s ease-out 0s; cursor:pointer;}
		.navy{background:#0060AE;}
			.navy:hover{background:#1E95C8;}
		.grey{background:#545B60;}
			.grey:hover{background:#83898d;}
		.green{background:#5D9B00;}
			.green:hover{background:#91C12D;}
			a.green:hover{color:#fff;}
		.blue{background:#1B98DF;}
		.darkGreen{background:#5d9b00;}
		.pink{background:#BD056A;}
			.pink:hover{background:#83054a;}
		.amber{background:#FEBE00;}
			.amber:hover{background:#b78a04;}
		#global .disabled{background:#ccc; opacity:0.8; cursor:default;}
		#global .disableIcon{color:#ccc; opacity:0.8; cursor: default;}
			#global .disableIcon .fa{color:#ccc;}
	.parsley-errors-list{float:left; clear:both; width:100%;}
		.warning i, .parsley-errors-list{color:#ED1C2A;}
		.parsley-errors-list li{float:left; width:100%; text-align:left; font-size:12px;}
			.frm .icons .parsley-errors-list li{text-align:left;}
		.measurementInputs .parsley-errors-list li{width:88%;}
			.parsley-errors-list li:before{font-family:FontAwesome; content:"\f077"; float:left; margin:-2px 5px 0 0;}
	.required{color:#ED1C2A;}
	.frm .icons li{text-align:center;}
		.frm .icons label{margin:0; text-align:center; color:#fff; font-size:13px; float:none;}
		.icons input{display:none;}
		.frm .icons small{font-size:12px; font-weight:bold;}
		.frm .icons figure{float:left; width:9%; padding-left:0;}
		.icons .col-md-3{text-align:center;}
		.icons img{display:inline-block; margin:-7px 0 0;}
			.frm .iconsFlue img{margin:0;}
			.frm .iconsFlue img:hover{opacity:0.8; cursor:pointer;}
		.btnCircle{background:#5d9b00; border-radius:50%; font-size:16px; font-weight:normal; height:65px; line-height:50px; padding:6px 0; text-align:center; width:65px; display:inline-block; transition:all .2s ease-out;}
			.btnCircle img{margin:0\0;}
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){.btnCircle img{margin:0;}}
			.btnCircle:hover, .active .btnCircle, table button.active, .flueTypeButtons .active button, #boilerTable .active button{background:#002D6D;}
			.btnDefault, .btnDefault:hover{background:none; cursor:default;}
				.btnDefault img{width:75%; height:auto;}
				.btnSmallSvg img{width:95%;}
.inputCont{float:left; width:100%;}
	.inputGroups{float:left; width:100%; display:table; position:relative; margin:0 0 15px;}
		.frm fieldset .inputGroups input{display:table-cell; vertical-align:top; margin:0; float:none; border-bottom-left-radius:0; border-top-left-radius:0;}
		.inputGroups .uiIcon{background:#eee; width:0.01%; display:table-cell; padding:0 10px; vertical-align:middle; white-space:nowrap; border:1px solid #ccc; border-radius:3px; transition:all .2s ease-out; color:#555; border-bottom-right-radius:0; border-top-right-radius:0; border-right:none;}
			.frm fieldset .groupRight input{border-right:none; border-radius:3px 0 0 3px;}
			.frm fieldset .groupRight .uiIcon{border-radius:0 3px 3px 0; border-right:1px solid #ccc; width:65px; text-align:center;}
	.customErrorCont{float:left; width:100%;}
		.cont.icons .customErrorCont{margin:15px 0 0;}
	.frm fieldset li{margin:0 0 12px;}
.frm .actions{float:left; width:100%; text-align:center;}
	.frm .actions a{float:none; width:auto; margin:5px 0 0; display:inline-block;}
		.frm .actions a:first-child{margin-left:0;}
		.frm .actions a:hover{color:#91C12D;}
#global .addPart{float:left; margin:0 12px 0 0;}

/* Phase */
.phase{transform:translateZ(0, 0, 0); backface-visibility:hidden; perspective:1000;}
.phase, .phase .cont{float:left; width:100%; margin:0 0 12px;}
	.phase .cont{margin:0; background:#fff; border:1px solid #F2F1ED; border-top:0; box-shadow:2px 1px 5px rgba(0, 0, 0, 0.09); padding:10px 25px 10px; position:relative;}
	.phase header{position:relative; float:left; width:100%; background:#1B98DF; color:#fff; font-size:14px; padding:8px 25px; border-radius:6px 6px 0 0;}
		.phase header h3{color:#fff; font-size:20px; margin:0;}
			.phase header h3 span{float:left;}
				.phase header h3 span.amount{float:none;}
				.info, .info:hover, .info:active{text-decoration:none; border-bottom:1px dotted #FFF; color:#FFF;}
					.info{float:left; font-size:12px; margin:6px 0 0 12px;}
					.info:hover{opacity:0.8;}
					.dob .info{float:none; display:inline-block; margin:0px 0 0 6px !important;}
		.phase .cont fieldset{width:100%;}
			.phase .cont figure{margin:8px 0 0;}
			.infoTitle{color:#0060AE;}
	.phase .icons figure{text-align:center;}
		.phase .icons figure img{display:inline-block;}
.phase + .phase, .step + .step{/* display:none; */}
.additionalFields > li + li, .problemDescr1iption + fieldset{border-top:1px dotted #ccc; padding-top:24px;}
.fa-trash-o{color:#BD056A;}
.phaseTable .btnCont, .phaseTable .btnCont button{margin:0;}
.phase fieldset .customCheckbox{float:left; width:100%;}
.frm .yellowWarning{background:#FCF8E3; border-color:#faebcc; color:#8a6d3b;}
	.frm .alert{margin:0;}
	.yellowWarning h3, .yellowWarning a, .yellowWarning h3, .yellowWarning a{color:#8a6d3b;}
	.frm .alert .iconCont{text-align:center;}
		.frm .alert .iconCont .fa{margin:1 auto 0; display:inline-block;}
	.frm .alert p{font-size:14px; margin:0;}
		.frm .alert h3{margin:0 0 12px;}
.frm .greenOk{background:#DFF0D8; border-color:#d6e9c6; color:#3c763d;}
	.frm .greenOk a{color:#2b542c;}
#signatureParent{float:left; width:100%; border:1px solid #ccc; font-size:13px; outline:none; transition:border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset; color:#555; padding:8px 12px; border-radius:3px; margin:10px 0 0; height:150px;}
	canvas.jSignature{height:184px !important;}
	.signature{float:left; width:100%;}
.frm .phase .problemDescription{border-bottom:1px dotted #ccc; margin:0 0 30px;}
.btnResetSig, .quoteCopy p, .endCopy p, .signatureFallback{display:none;}
.extraInfo .normalList{margin:0 0 15px;}
	.extraInfo .normalList span{font-weight:bold;}
.optionsCont{float:left; width:100%;}
	.frm .optionsCont input{display:none;}
		input.radiocheck + label.nofull.default.left{font-size:14px; padding-top:4px;}
	.frm .optionsCont label{margin:0 0 12px;} 
.step4 .phase1, .reasonReturnVisit{display:none;}
.step4 .phase2{display:block;}
.btnResetSig{float:left; width:100%; margin:12px 0 0;}
.btnCont small{margin:0 0 4px;}
.customerName{text-transform:uppercase;}

/* Warning panels */
.alert{float:left; width:100%; margin:0 0 15px;background:#f2dede; border-color:#ebccd1; color:#a94442; border-radius:6px; padding:15px;}
	.alert a{color:#a94442; font-weight:bold; text-decoration:underline;}
.alertMaintenance{background:#FCF8E3; border-color:#faebcc; color:#8a6d3b;}
	.alertMaintenance h4, .alertMaintenance a{color:#8a6d3b;}
.sweet-alert h2{margin:10px 0;}
	
/* Progress UI */
.sessionLoader{float:left; width:100%; margin:0 0 25px;}
	.progressCont{width:100%; border:1px solid #ddd; border-radius:6px; overflow:hidden; display:inline-block; vertical-align:top;}
		.progress{color:#fff; text-align:right; height:25px; width:0;background:#5D9B00; border-radius:6px; line-height:25px;}

/* Sliders */
.sliderCol{float:left; width:74%; margin:2px 0 12px 12px}
.noUi-connect{background:#5D9B00;}
.noUi-handle{cursor:pointer;}

/* Tables */
.summaryTable{width:100%; box-shadow:2px 1px 5px rgba(0, 0, 0, 0.09); float:left; display:none; }
	.careplanTable, .quoteSummaryTable{display:inline-table;}
	.summaryTable td, .summaryTable th{background:#fafafa; color:#545b60; font-size:12px; border-bottom:1px solid #ccc; cursor:pointer;}
		.summaryTable tr:hover td{background:#eee;}
		.summaryTable th{vertical-align:middle; padding:15px 0; font-weight:bold; border-top:1px solid #ccc;}
		.summaryTable td:first-child, .summaryTable th:first-child{text-align:left; vertical-align:middle; width:35%; padding-left:25px;}
		.summaryTable td:last-child, .summaryTable th:last-child{padding:8px 25px; text-align:center; width:8%;}
		.careplanTable td:last-child, .careplanTable th:last-child{text-align:left; vertical-align:middle; width:35%;}
			.summaryTable td{text-align:center; transition:all .2s ease-out; padding:8px 0;}
				.frm .summaryTable .actions a:first-child{margin:0;}
		#global .frm .careplanTable td input[type="radio"], #global .frm .careplanTable td input[type="checkbox"], #global .frm .careplanTable td label{float:none; display:inline-block;}
			#global .frm .careplanTable td input[type="radio"], #global .frm .careplanTable td input[type="checkbox"]{display:none;}
				td .optionsCont{margin:5px 0 0;}
		#global .frm .careplanTable td label{font-size:12px; font-weight:normal;}
		#global .frm .careplanTable select{margin:0; float:none;}
.quoteSummaryTable h4{margin:0; font-size:16px;}
.quoteSummaryTable td{font-size:14px;}
	.quoteSummaryTable td:last-child, .quoteSummaryTable th:last-child{text-align:right; vertical-align:middle; width:35%;}
table.careplanOverlayTable{width:100%; float:left;font-weight:bold; font-size:12px; color:#545b60; margin:0 0 10px;}
	.careplanOverlayTable thead tr > th{background:#fff; border-bottom:1px solid #ccc; vertical-align:middle; padding:0 12px 12px;}
		.overlay .careplanOverlayTable table tr td{border:none; padding:5px 0 0; background:none;}
		.careplanOverlayTable th:first-child, .careplanOverlayTable th:last-child{width:14%;}
			.innerTable{width:24%;}
	.careplanOverlayTable tbody tr > td{text-align:center; transition:all .2s ease-out; padding:15px 12px; background:#fafafa; vertical-align:middle; border-bottom:1px solid #ccc; cursor:pointer;}
	.careplanOverlayTable tbody tr:hover td{background:#eee;}
		.careplanOverlayTable thead tbody tr:hover td{background:#fafafa;}
	.careplanOverlayTable .fa-times{color:#bd056a;}
	.careplanOverlayTable .fa-check{color:#5d9b00;}
	.careplanOverlayTable tr.active td{background:#cbcbcb;}

/* Progress */
.progressBar{margin:28px 0 28px; display:none;}
	.progressBar, .progressBar ul{float:left; width:100%; position:relative;}
	.progressBar li{float:left; width:25%; position:relative; z-index:12; cursor:pointer;}
	.progressBar ul:before {content:""; display:block; border-top:1px dotted #ddd; border-bottom: 1px solid #fff; width:100%; height:1px; position:absolute; top:30%; z-index:10;}
		.progressBar li a{float:left; text-align:center; width:100%;}	
		.progressBar li span{background:#fff; border-radius:50%; display:block; font-size:20px; width:40px; height:40px; line-height:40px; position:relative; text-align:center; margin:4px auto 12px; color:#646361;}
			.progressBar li span:before{border-radius:50%; background:#fff; border-bottom:24px solid #ddd; border-top:26px solid #ddd; content:""; display:block; position:absolute; bottom:-5px; left:-5px; right:-5px; top:-5px; z-index:-1; box-shadow:0 8px 15px #eee inset;}
			.progressBar a,.progressBar span, .progressBar span:before{transition:all .2s ease-out; cursor:default;}
			/*default*/
			.progressBar li:hover, .progressBar li a{cursor:default;}
				.progressBar li a:hover{color:#768187;}
			/*active*/
			.progressBar li.active span{background:#129adb; background:radial-gradient(ellipse at center, #129adb 0%,#1684c6 100%); color:#fff;}
			.progressBar li.active span:before{background:#fff; border-top:2px solid #ddd; border-bottom:2px solid #eee;}
				.progressBar li.active a{color:#129adb; font-weight:bold;}
			/*oldStep*/
			.progressBar li.completedStep span{background:#545b60;}
			.progressBar li.completedStep span:before{display:none;}
				.progressBar li.completedStep a{font-weight:normal; color:#545b60;}

/* Estimate */
.estimateCont{display:none; float:left; width:100%; margin:2px 0 0;}
.estimateForms, .quoteForms{float:left; width:100%; margin:0 0 12px;}
	#frmEstimate button{margin-left:0;}
#frmEstimate .cont h3, .table-responsive{float:left; width:100%;}
	.table{margin:0; border-collapse:collapse;}
#frmEstimate .btnCont{margin-bottom:0; position:relative;}
	#frmEstimate .saveEstimate{margin-left:12px;}
	#frmEstimate .btnCont .preloader{position:absolute; right:-29px; top:6px;}
#estimateBreakdown header{padding:0 43px 0 25px;}
	#estimateBreakdown h3{margin:15px 0; width:auto;}
	.ribbon{float:right; background:#0a2e6c; padding:15px 45px 0; max-width:198px; text-align:center; font-size:16px; position:relative;}
		.ribbon span{float:left; width:100%; position:relative; z-index:2;}
			.triangle{width:0; height:0; border-style:solid; border-width:27px 99px 0; border-color:#0a2e6c transparent transparent transparent; position:absolute; bottom:-27px; left:0; z-index:1;}
#estimateBreakdown .cont{padding:28px 0 15px; overflow:hidden;}
	.estimateList dl{margin-top:20px; padding:0 25px;}
		.estimateList .totalPriceRange{background:#e5f6fd; padding:12px 25px;}
			.estimateList .totalPriceRange h4{color:#072e69; margin:0 0 15px;}
	.estimatePrice{text-align:center; margin-top:0 !important;}
	.estimateList h4{font-size:1.3em; color:#646361; margin:0 0 6px;}
		.estimateList h4 em{font-weight:normal; font-style:normal; font-size:0.9em;}
	.estimateList small{margin:0;}
	.estimateList .listCont{margin:0;}
		.estimateList .listCont li{margin-top:3px;}
			.estimateList .listCont span{width:96%;}
	.stepsAhead, .extraInfo{padding:0 25px;}
		.estimateArticle{padding:0 30% 15px 25px; margin:-30px 0 27px; border-bottom:1px dotted #ccc; float:left; width:100%;}
		.extraInfo{float:left; width:100%; margin:12px 0 0;}
		.stepsAhead .btnCont{margin:0;}
			.stepsAhead .btnCont button, .stepsAhead .btnCont input{width:100%; text-align:center; padding:13px;}
		.stepsAhead .normalList li{margin:0 0 3px;}
	.extraInfo .conts{border-top:1px dotted #c1c1c1; padding-top:25px; float:left; width:100%;}
	.contEligibility{float:left; width:100%; margin:20px 0 0;}
.estimateList .col-md-4{width:31.5%;}
.estimateList .hidden-xs{margin:0 12px 0 0;}

/* Autocomplete and Select */
.autocomplete-suggestions{background:#FFF; cursor:default; overflow:auto; border:1px solid #ccc; border-top:none; transition:border-color 0.15s ease-in-out 0s box-shadow 0.15s ease-in-out 0s; box-shadow:0 1px 1px rgba(0, 0, 0, 0.075) inset; color:#555; border-radius:0 0 3px 3px;}
	.autocomplete-suggestion, .select2-results li{padding:8px 12px; white-space:nowrap; overflow:hidden; cursor:pointer; transition:all 0.2s ease-out 0s;}
		.autocomplete-no-suggestion{padding:5px 0;}
		.autocomplete-selected, .select2-results .select2-highlighted{background:#EEE;}
			.autocomplete-suggestions strong{font-weight:bold; color:#555555;}
.select2-container{width:100%; margin:0 0 12px;}
	.select2-container .select2-choice{background:none; padding:8px 12px; border-radius:3px; border-color:#ccc; color:#555; line-height:normal; height:auto;}
		.select2-drop-active{border:1px solid #ccc; border-top:none; box-shadow:none; color:#555; border-radius:0 0 3px 3px;}
		.select2-container-active{border-color:#1B98DF; box-shadow:none; outline:0 none;}
			.select2-results .select2-highlighted{color:#555555;}
			
/* Signature conf */
.sweet-alert p{float:left; margin:0 0 12px !important;}
canvas.jSignature{border:1px solid #000 !important; margin:0 0 15px !important; height:325px;}

#title{float:left; width:auto; margin:40px 0 0 40px;}